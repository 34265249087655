import {
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import * as loginPageTranslations from '../../../../../assets/i18n/lang/en/loginPage.json';
import { GuestLoginService } from '../../services/guest-login.service';
import { LocalStorageKey } from '../../../../shared/constants/local-storage-key.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AdiHomeLoggingService } from '../../../../shared/services/adi-home-logging/adi-home-logging.service';
import { LogLevel } from '../../../../shared/constants/log-type.consts';
import { WindowLocationService } from '../../../../shared/services/window-location-service/window-location.service';
import { LogObject } from '../../../../shared/interfaces/logging.interface';

@Component({
  selector: 'adi-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  private windowLocationService = inject(WindowLocationService);
  destroyRef = inject(DestroyRef);

  loginPageTranslations: any = loginPageTranslations;
  public languageOptions!: any[];
  selectedLocale = '';
  selectedLanguage: any = '';
  defaultLocale = '';
  footerLinks: any;
  currentYear: any;
  showDropdown: boolean = false;
  @Output() data = new EventEmitter<any>();

  constructor(
    public guestService: GuestLoginService,
    public loggingService: AdiHomeLoggingService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.fetchLanguages();
  }

  selectLanguage(language: any): void {
    const locale = language.code;
    this.selectedLanguage = language.language;
    this.selectedLocale = language.code;
    localStorage.setItem(
      LocalStorageKey.SELECTED_LANGUAGE,
      this.selectedLocale
    );
    localStorage.setItem(
      LocalStorageKey.SELECTED_LANGUAGE_NAME,
      this.selectedLanguage
    );
    const newUrl = `${window.location.origin}/${locale}/login`;
    this.windowLocationService.openLink(newUrl);
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  setSelectedLocaleFromRoute() {
    const currentLocale = localStorage.getItem(
      LocalStorageKey.SELECTED_LANGUAGE
    )!;
    const isLocaleValid = this.languageOptions.some(
      (language: { code: string }) =>
        language.code.split('_')[0] === currentLocale.split('_')[0]
    );

    const urlLocaleIndex = this.languageOptions.findIndex(
      (item) => item.code.split('_')[0] === currentLocale.split('_')[0]
    );

    this.selectedLocale = this.languageOptions[urlLocaleIndex].code;
    this.selectedLanguage = this.languageOptions[urlLocaleIndex].language;

    if (isLocaleValid) {
      if (this.selectedLocale !== currentLocale) {
        localStorage.setItem(
          LocalStorageKey.SELECTED_LANGUAGE,
          this.selectedLocale
        );
        localStorage.setItem(
          LocalStorageKey.SELECTED_LANGUAGE_NAME,
          this.selectedLanguage
        );
        this.navigateToLocale(this.selectedLocale);
      } else {
        this.selectedLocale = currentLocale;
        localStorage.setItem(
          LocalStorageKey.SELECTED_LANGUAGE,
          this.selectedLocale
        );
        localStorage.setItem(
          LocalStorageKey.SELECTED_LANGUAGE_NAME,
          this.selectedLanguage
        );
        this.fetchFooterLinks(this.selectedLocale);
      }
    } else {
      this.selectedLocale = this.languageOptions[0].code;
      this.selectedLanguage = this.languageOptions[0].language;
      localStorage.setItem(
        LocalStorageKey.SELECTED_LANGUAGE,
        this.selectedLocale
      );
      localStorage.setItem(
        LocalStorageKey.SELECTED_LANGUAGE_NAME,
        this.selectedLanguage
      );
      this.navigateToDefaultLocale();
    }
  }

  navigateToDefaultLocale() {
    this.defaultLocale = this.languageOptions[0].code;
    const newUrl = `${window.location.origin}/${this.defaultLocale}/login`;
    this.windowLocationService.openLink(newUrl);
  }

  fetchLanguages() {
    try {
      this.guestService
        .getLoginPageData()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: any) => {
          this.languageOptions = res;
          this.data.emit(this.languageOptions);
          this.setSelectedLocaleFromRoute();
        });
    } catch (err: any) {
      const logObj: LogObject = {
        eventType: 'Fetch Guest Page Data',
        eventDetail: `Fetch Guest Page Data Error: ${err}`,
        logLevel: LogLevel.ERROR,
      };
      this.loggingService
        .sendFrontendLogs(logObj)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }

  onLanguageChange(event: Event): void {
    const selectElement = event.target;
    if (selectElement && (selectElement as HTMLSelectElement).value) {
      const locale = (selectElement as HTMLSelectElement).value;
      localStorage.setItem(
        LocalStorageKey.SELECTED_LANGUAGE,
        this.selectedLocale
      );
      const newUrl = `${window.location.origin}/${locale}/login`;
      this.windowLocationService.openLink(newUrl);
    }
  }

  navigateToLocale(locale: string): void {
    const url = `${window.location.origin}/${locale}/login`;
    this.windowLocationService.openLink(url);
  }

  fetchFooterLinks(code: string) {
    const a = this.languageOptions.filter(
      (codeVal: { code: string }) => codeVal.code === code
    );
    this.footerLinks = a[0].footerLinks || '';
  }

  redirectToDefaultLocale(): void {
    this.navigateToLocale(this.defaultLocale);
  }
}
